<template>
<vs-row justify="flex-start" class="admin" style="padding-left: 270px; padding-top: 50px; height: 75vh;" v-if="$parent.user != null">
    <input type="file" ref="imageInput" style="display: none;" accept="image/*" @change="fileInputChange" />
    <input type="file" ref="pdfInput" style="display: none;" accept="application/pdf" @change="fileInputChange" />
    <vs-sidebar absolute v-model="active" open style="height: 81vh; ">

        <vs-sidebar-item id="products">
            <template #icon>
                <i class='bx bxs-purchase-tag-alt'></i>
            </template>
            Prodotti
        </vs-sidebar-item>
        <vs-sidebar-item id="users" disabled>
            <template #icon>
                <i class='bx bxs-group'></i>
            </template>
            Utenti ({{ users_count }})
        </vs-sidebar-item>
        <vs-sidebar-item id="orders">
            <template #icon>
                <i class='bx bxs-cart'></i>
            </template>
            Ordini
        </vs-sidebar-item>
        <!--<vs-sidebar-item id="invoices">
            <template #icon>
                <i class='bx bxs-credit-card'></i>
            </template>
            Fatture
        </vs-sidebar-item>-->

        <vs-sidebar-item id="fonts">
            <template #icon>
                <i class='bx bx-text'></i>
            </template>
            Fonts
        </vs-sidebar-item>

    </vs-sidebar>

    <vs-dialog prevent-close v-model="editFontDialog" class="dialog-large dialog-disabled" :loading="editFontDialogLoading">
        <template #header>
            <h4 style="margin-bottom: 0;">
                Carica <b>font</b>
            </h4>

        </template>

        <vs-row v-if="font != null">
            <!-- BASE FIELDS -->
            <vs-row style="gap: 30px;">
                <vs-input type="text" label="Nome" v-model="font.name" block />
                <input type="file" ref="ttfInput" style="display: none;" accept="font/ttf" @change="fileInputChangeTTF" />
                <vs-button icon color="primary" block @click="$refs.ttfInput.click()">
                    <i class='bx bx-upload'></i>
                    {{ font.fileId != null ? 'Sostituisci file' : 'Carica file' }}
                </vs-button>
            </vs-row>

        </vs-row>

        <template #footer>
            <div class="footer-dialog">
                <vs-button block @click="saveFont">
                    Salva modifiche
                </vs-button>
            </div>
        </template>
    </vs-dialog>

    <vs-dialog prevent-close v-model="invoiceDialog" class="dialog-large" :loading="invoiceDialogLoading">
        <template #header>
            <h4 style="margin-bottom: 0;">
                <b>Convalida pagamento.</b>
            </h4>

        </template>

        <vs-row v-if="invoice != null">
            <!-- BASE FIELDS -->

            <vs-row style="margin-top: 20px;">
                <vs-input type="number" label="Importo" v-model="invoice.amount" block />
            </vs-row>
        </vs-row>

        <template #footer>
            <div class="footer-dialog">
                <vs-button block @click="saveInvoice">
                    Salva modifiche
                </vs-button>
            </div>
        </template>
    </vs-dialog>

    <vs-dialog prevent-close v-model="editProductDialog" class="dialog-large dialog-disabled" :loading="editProductDialogLoading">
        <template #header>
            <h4 style="margin-bottom: 0;">
                Modifica <b>{{ product != null ? product.title : '' }}</b>
            </h4>

        </template>

        <vs-row v-if="product != null">
            <!-- BASE FIELDS -->
            <vs-row style="gap: 30px;">
                <vs-input type="text" label="Titolo" v-model="product.title" block />
                <vs-input type="text" label="Descrizione" v-model="product.description" block />

                <vs-input type="number" label="Larghezza" v-model="product.width" block />
                <vs-input type="number" label="Altezza" v-model="product.height" block />
                <vs-checkbox v-model="product.emptyBackCover">
                    Pagine bianche
                </vs-checkbox>
            </vs-row>

            <vs-row justify="space-between">
                <vs-col w="9">
                    <vs-button icon color="primary" block @click="uploadLayoutFile(null, 'productFileId')" :loading="uploadFileLoading && uploadFileForField == 'productFileId'">
                        <i class='bx bx-upload'></i>
                        {{ product.fileId != null ? 'Sostituisci anteprima prodotto' : 'Carica anteprima prodotto' }}
                    </vs-button>
                </vs-col>

                <vs-col w="3">
                    <vs-button icon color="primary" flat :disabled="product.fileId == null" @click="openProductFile(product.fileId)" block>
                        <i class='bx bx-search-alt'></i>
                    </vs-button>
                </vs-col>
            </vs-row>

            <!-- THEME MANAGEMENT -->
            <vs-row justify="space-between" align="center">
                <h2>Varianti</h2>
                <vs-button icon color="primary" @click="addProductVariant">
                    <i class='bx bx-plus'></i>
                    Aggiungi nuova
                </vs-button>
            </vs-row>
            <vs-table striped style="width: 100%;">
                <template #thead>
                    <vs-tr>
                        <vs-th style="width: 5%;">
                            Id
                        </vs-th>
                        <vs-th>
                            Pagine
                        </vs-th>
                        <vs-th>
                            Prezzo
                        </vs-th>
                        <vs-th>
                            Prezzo Digitale
                        </vs-th>
                        <vs-th>
                            Stipe PID
                        </vs-th>
                        <vs-th>
                            Stipe PID Digitale
                        </vs-th>

                    </vs-tr>
                </template>
                <template #tbody>
                    <vs-tr :key="i" v-for="(tr, i) in product.productVariants" :is-selected="tr == productVariant" @click="productVariant = tr">
                        <vs-td>
                            {{ tr.id }}
                        </vs-td>
                        <vs-td>
                            {{ tr.pages }}
                        </vs-td>
                        <vs-td>
                            {{ tr.price }}
                        </vs-td>
                        <vs-td>
                            {{ tr.digitalPrice }}
                        </vs-td>
                        <vs-td>
                            {{ tr.stripePriceId }}
                        </vs-td>
                        <vs-td>
                            {{ tr.digitalStripePriceId }}
                        </vs-td>

                    </vs-tr>
                </template>
            </vs-table>

            <!-- THEME MANAGEMENT -->
            <vs-row justify="space-between" align="center">
                <h2>Temi</h2>
                <vs-button icon color="primary" @click="addTheme">
                    <i class='bx bx-plus'></i>
                    Aggiungi nuovo
                </vs-button>
            </vs-row>
            <vs-table striped style="width: 100%;">
                <template #thead>
                    <vs-tr>
                        <vs-th style="width: 5%;">
                            Id
                        </vs-th>
                        <vs-th>
                            Titolo
                        </vs-th>

                    </vs-tr>
                </template>
                <template #tbody>
                    <vs-tr :key="i" v-for="(tr, i) in product.themes" :is-selected="tr == theme" @click="theme = tr">
                        <vs-td>
                            {{ tr.id }}
                        </vs-td>
                        <vs-td>
                            {{ tr.title }}
                        </vs-td>

                    </vs-tr>
                </template>
            </vs-table>
            <!-- LAYOUT MANAGEMENT -->
            <vs-row justify="space-between" align="center">
                <h2>Layout</h2>
                <vs-button icon color="primary" @click="addLayout">
                    <i class='bx bx-plus'></i>
                    Aggiungi nuovo
                </vs-button>
            </vs-row>
            <vs-table striped style="width: 100%;" v-model="layout">
                <template #thead>
                    <vs-tr>
                        <vs-th style="width: 5%;">
                            Id
                        </vs-th>
                        <vs-th>
                            Copertina iniziale
                        </vs-th>
                        <vs-th>
                            Copertina finale
                        </vs-th>
                        <vs-th>
                            Elementi
                        </vs-th>
                        <vs-th>
                            PDF Files
                        </vs-th>
                        <vs-th>
                            Preview Files
                        </vs-th>
                    </vs-tr>
                </template>
                <template #tbody>
                    <vs-tr :key="i" v-for="(tr, i) in product.layouts" :is-selected="layout == tr" @click="layout = tr">
                        <vs-td>
                            {{ tr.id }}
                        </vs-td>
                        <vs-td>
                            {{ tr.isFrontCover ? 'Sì' : 'No' }}
                        </vs-td>
                        <vs-td>
                            {{ tr.isBackCover ? 'Sì' : 'No' }}
                        </vs-td>
                        <vs-td>
                            {{ tr.elements.length }}
                        </vs-td>
                        <vs-td>
                            {{ tr.layoutFiles.filter(e => e.fileId != null).length }} / {{ product.themes.length }}
                        </vs-td>
                        <vs-td>
                            {{ tr.layoutFiles.filter(e => e.previewFileId != null).length }} / {{ product.themes.length }}
                        </vs-td>

                    </vs-tr>
                </template>
            </vs-table>

        </vs-row>

        <template #footer>
            <div class="footer-dialog">
                <vs-button block @click="saveProduct">
                    Salva modifiche
                </vs-button>
            </div>
        </template>
    </vs-dialog>
    <vs-dialog prevent-close v-model="editLayoutDialog" class="dialog-large" :loading="editLayoutDialogLoading">
        <template #header>
            <h4 style="margin-bottom: 0;">
                Modifica <b>Layout #{{ layout != null ? layout.id : '' }}</b>
            </h4>

        </template>

        <vs-row v-if="layout != null">

            <!-- BASE FIELDS -->
            <vs-row>

                <vs-checkbox v-model="layout.isFrontCover" block>
                    Copertina iniziale
                </vs-checkbox>

            </vs-row>
            <vs-row>
                <vs-checkbox v-model="layout.isBackCover">
                    Copertina finale
                </vs-checkbox>
            </vs-row>
            <vs-row>
                <vs-checkbox v-model="layout.isBlankPage">
                    Pagina bianca
                </vs-checkbox>
            </vs-row>

            <!-- THEME MANAGEMENT -->
            <h2>Temi</h2>
            <vs-table striped style="width: 100%;">
                <template #thead>
                    <vs-tr>
                        <vs-th style="width: 5%;">
                            Id
                        </vs-th>
                        <vs-th>
                            Titolo
                        </vs-th>
                        <vs-th style="width: 30%;">
                            PDF File
                        </vs-th>
                        <vs-th style="width: 30%;">
                            PNG File (Preview)
                        </vs-th>

                    </vs-tr>
                </template>
                <template #tbody>
                    <vs-tr :key="i" v-for="(tr, i) in product.themes">
                        <vs-td>
                            {{ tr.id }}
                        </vs-td>
                        <vs-td>
                            {{ tr.title }}
                        </vs-td>
                        <vs-td style="padding:0;">
                            <vs-row justify="space-between">
                                <vs-col w="9">
                                    <vs-button icon color="primary" block @click="uploadLayoutFile(tr.id, 'fileId')" :loading="uploadFileLoading && uploadFileForField == 'fileId' && uploadFileForThemeId == tr.id">
                                        <i class='bx bx-upload'></i>
                                        {{ layout.layoutFiles.find(e => e.themeId == tr.id && e.fileId != null) != null ? 'Sostituisci' : 'Carica' }}
                                    </vs-button>
                                </vs-col>

                                <vs-button icon color="primary" flat :disabled="layout.layoutFiles.find(e => e.themeId == tr.id) == null || layout.layoutFiles.find(e => e.themeId == tr.id).fileId == null" @click="openProductFile(layout.layoutFiles.find(e => e.themeId == tr.id).fileId, true)">
                                    <i class='bx bx-search-alt'></i>
                                </vs-button>
                            </vs-row>
                        </vs-td>
                        <vs-td style="padding:0;">
                            <vs-row justify="space-between">
                                <vs-col w="9">
                                    <vs-button icon color="primary" block @click="uploadLayoutFile(tr.id, 'previewFileId')" :loading="uploadFileLoading && uploadFileForField == 'previewFileId' && uploadFileForThemeId == tr.id">
                                        <i class='bx bx-upload'></i>
                                        {{ layout.layoutFiles.find(e => e.themeId == tr.id && e.previewFileId != null) != null ? 'Sostituisci' : 'Carica' }}
                                    </vs-button>
                                </vs-col>

                                <vs-button icon color="primary" flat :disabled="layout.layoutFiles.find(e => e.themeId == tr.id) == null || layout.layoutFiles.find(e => e.themeId == tr.id).previewFileId == null" @click="openProductFile(layout.layoutFiles.find(e => e.themeId == tr.id).previewFileId)">
                                    <i class='bx bx-search-alt'></i>
                                </vs-button>
                            </vs-row>
                        </vs-td>

                    </vs-tr>
                </template>
            </vs-table>
            <!-- ELEMENTS MANAGEMENT -->
            <vs-row justify="space-between" align="center">
                <h2>Elementi</h2>
                <vs-button icon color="primary" @click="addElement">
                    <i class='bx bx-plus'></i>
                    Aggiungi nuovo
                </vs-button>
            </vs-row>
            <vs-table striped style="width: 100%;">
                <template #thead>
                    <vs-tr>
                        <vs-th style="width: 5%;">
                            Id
                        </vs-th>
                        <vs-th>
                            Type
                        </vs-th>
                        <vs-th>
                            Title
                        </vs-th>
                        <vs-th>
                            PosX
                        </vs-th>
                        <vs-th>
                            PosY
                        </vs-th>
                        <vs-th>
                            Height
                        </vs-th>
                        <vs-th>
                            Width
                        </vs-th>
                    </vs-tr>
                </template>
                <template #tbody>
                    <vs-tr :key="i" v-for="(tr, i) in layout.elements" :is-selected="element == tr" @click="element = tr">
                        <vs-td>
                            {{ tr.id }}
                        </vs-td>
                        <vs-td>
                            {{ tr.title }}
                        </vs-td>
                        <vs-td>
                            {{ tr.type }}
                        </vs-td>
                        <vs-td>
                            {{ tr.posX }}pt
                        </vs-td>
                        <vs-td>
                            {{ tr.posY }}pt
                        </vs-td>
                        <vs-td>
                            {{ tr.height }}pt
                        </vs-td>
                        <vs-td>
                            {{ tr.width }}pt
                        </vs-td>

                    </vs-tr>
                </template>
            </vs-table>

        </vs-row>

        <template #footer>
            <div class="footer-dialog">
                <vs-button block @click="editLayout">
                    Salva modifiche
                </vs-button>
            </div>
        </template>
    </vs-dialog>
    <vs-dialog prevent-close v-model="editElementDialog" class="dialog-large" :loading="editElementDialogLoading">
        <template #header>
            <h4 style="margin-bottom: 0;">
                Modifica <b>{{ element != null ? element.title : '' }}</b>
            </h4>

        </template>

        <vs-row v-if="element != null">
            <!-- BASE FIELDS -->
            <vs-row style="gap: 30px;">
                <vs-input type="text" label="Titolo" v-model="element.title" block />
                <vs-select placeholder="Seleziona.." label="Tipo" v-model="element.type" block>
                    <vs-option label="Testo" value="TEXT">
                        Testo
                    </vs-option>
                    <vs-option label="Immagine" value="IMAGE">
                        Immagine
                    </vs-option>
                    <vs-option label="QR Code" value="QR">
                        QR Code
                    </vs-option>
                </vs-select>
                <vs-input type="number" label="PosX" v-model="element.posX" block />
                <vs-input type="number" label="PosY" v-model="element.posY" block />
                <vs-input type="number" label="Altezza" v-model="element.height" block />
                <vs-input type="number" label="Larghezza" v-model="element.width" block />

                <vs-select placeholder="Seleziona.." label="Allinamento verticale predefinito" block v-model="element.defaultFontValign">
                    <vs-option :label="'Alto'" :value="'TOP'">
                        Alto
                    </vs-option>
                    <vs-option :label="'Centrato'" :value="'MIDDLE'">
                        Centrato
                    </vs-option>
                    <vs-option :label="'Basso'" :value="'BOTTOM'">
                        Basso
                    </vs-option>
                </vs-select>
                <vs-select placeholder="Seleziona.." label="Allinamento orizzontale predefinito" block v-model="element.defaultFontHalign">
                    <vs-option :label="'Sinistra'" :value="'LEFT'">
                        Sinistra
                    </vs-option>
                    <vs-option :label="'Centrato'" :value="'CENTER'">
                        Centrato
                    </vs-option>
                    <vs-option :label="'Destra'" :value="'RIGHT'">
                        Destra
                    </vs-option>
                </vs-select>
                <textarea v-model="element.defaultText" placeholder="Testo predefinito"></textarea>
                <vs-checkbox v-model="element.skipPostSelection">
                    Salta passaggio selezione post
                </vs-checkbox>
                <vs-checkbox v-model="element.hidden">
                    Nascondi all'utente
                </vs-checkbox>

            </vs-row>

            <!-- DEFAULT ELEMENT FONTS PER THEME MANAGEMENT-->
            <vs-row justify="space-between" align="center">
                <h2>Font Predefiniti per tema</h2>

            </vs-row>
            <vs-table striped style="width: 100%;">
                <template #thead>
                    <vs-tr>
                        <vs-th style="width: 5%;">
                            Tema
                        </vs-th>
                        <vs-th>
                            Font
                        </vs-th>
                        <vs-th>
                            Colore
                        </vs-th>
                        <vs-th>
                            Dimensione
                        </vs-th>

                    </vs-tr>
                </template>
                <template #tbody>
                    <vs-tr :key="i" v-for="(tr, i) in product.themes" :is-selected="tr == theme" @click="newElementDefaultFont(element.id, tr.id)">
                        <vs-td>
                            {{ tr.title }}
                        </vs-td>
                        <vs-td>
                            {{ element.elementDefaultFonts.find(e => e.themeId == tr.id) != null ? element.elementDefaultFonts.find(e => e.themeId == tr.id).fontId : '-' }}
                        </vs-td>
                        <vs-td>
                            {{ element.elementDefaultFonts.find(e => e.themeId == tr.id) != null ? element.elementDefaultFonts.find(e => e.themeId == tr.id).color : '-' }}
                        </vs-td>
                        <vs-td>
                            {{ element.elementDefaultFonts.find(e => e.themeId == tr.id) != null ? element.elementDefaultFonts.find(e => e.themeId == tr.id).size : '-' }}
                        </vs-td>

                    </vs-tr>
                </template>
            </vs-table>

            <!-- DEFAULT ELEMENTS IMAGES PER THEME MANAGEMENT -->
            <h2>Elementi di default per tema (solo per immagini)</h2>
            <vs-table striped style="width: 100%;">
                <template #thead>
                    <vs-tr>
                        <vs-th style="width: 5%;">
                            Id
                        </vs-th>
                        <vs-th>
                            Titolo
                        </vs-th>
                        <vs-th style="width: 30%;">
                            PNG File
                        </vs-th>

                    </vs-tr>
                </template>
                <template #tbody>
                    <vs-tr :key="i" v-for="(tr, i) in product.themes">
                        <vs-td>
                            {{ tr.id }}
                        </vs-td>
                        <vs-td>
                            {{ tr.title }}
                        </vs-td>
                        <vs-td style="padding:0;">
                            <vs-row justify="space-between">
                                <vs-col w="9">
                                    <vs-button icon color="primary" block @click="uploadLayoutFile(tr.id, 'elementDefault')" :loading="uploadFileLoading && uploadFileForField == 'elementDefault' && uploadFileForThemeId == tr.id">
                                        <i class='bx bx-upload'></i>
                                        {{ element.elementDefaults.find(e => e.themeId == tr.id) != null ? 'Sostituisci' : 'Carica' }}
                                    </vs-button>
                                </vs-col>

                                <vs-button icon color="primary" flat :disabled="element.elementDefaults.find(e => e.themeId == tr.id) == null" @click="openProductFile(element.elementDefaults.find(e => e.themeId == tr.id).fileId)">
                                    <i class='bx bx-search-alt'></i>
                                </vs-button>
                            </vs-row>
                        </vs-td>

                    </vs-tr>
                </template>
            </vs-table>

        </vs-row>

        <template #footer>
            <div class="footer-dialog">
                <vs-button block @click="saveElement">
                    Salva modifiche
                </vs-button>
            </div>
        </template>
    </vs-dialog>
    <vs-dialog prevent-close v-model="elementDefaultFontDialog" class="dialog-large" :loading="elementDefaultFontDialogLoading">
        <template #header>
            <h4 style="margin-bottom: 0;">
                <b>Modifica font per il tema.</b>
            </h4>

        </template>

        <vs-row v-if="elementDefaultFont != null">
            <!-- BASE FIELDS -->
            <vs-row style="gap: 30px;">
                <vs-input type="color" label="Colore" v-model="elementDefaultFont.color" block />
            </vs-row>
            <vs-row style="margin-top: 20px;">
                <vs-input type="number" label="Dimensione" v-model="elementDefaultFont.size" block />
            </vs-row>

            <vs-select placeholder="Seleziona.." style="margin-top: 20px;" label="Font" block v-model="elementDefaultFont.fontId">
                <vs-option v-for="font in fonts" :key="'font-' + font.id" :label="font.name" :value="font.id">
                    {{ font.name }}
                </vs-option>
            </vs-select>
        </vs-row>

        <template #footer>
            <div class="footer-dialog">
                <vs-button block @click="saveElementDefaultFont">
                    Salva modifiche
                </vs-button>
            </div>
        </template>
    </vs-dialog>

    <vs-dialog prevent-close v-model="productVariantDialog" class="dialog-large" :loading="productVariantDialogLoading">
        <template #header>
            <h4 style="margin-bottom: 0;">
                <b>Variante prodotto.</b>
            </h4>

        </template>

        <vs-row v-if="productVariant != null" style="gap: 10px;">
            <vs-row>
                <vs-input type="number" label="Pagine" v-model="productVariant.pages" block />
            </vs-row>
            <vs-row>
                <vs-input type="number" label="Prezzo" v-model="productVariant.price" block />
            </vs-row>
            <vs-row>
                <vs-input type="number" label="Prezzo Digitale" v-model="productVariant.digitalPrice" block />
            </vs-row>
            <vs-row>
                <vs-input type="text" label="Stripe Price ID" v-model="productVariant.stripePriceId" block />
            </vs-row>
            <vs-row>
                <vs-input type="text" label="Digital Stripe Price ID" v-model="productVariant.digitalStripePriceId" block />
            </vs-row>
        </vs-row>

        <template #footer>
            <div class="footer-dialog">
                <vs-button block @click="saveProductVariant">
                    Salva modifiche
                </vs-button>
            </div>
        </template>
    </vs-dialog>

    <vs-dialog prevent-close v-model="editThemeDialog" class="dialog-large" :loading="editThemeDialogLoading">
        <template #header>
            <h4 style="margin-bottom: 0;">
                Modifica <b>{{ theme != null ? theme.title : '' }}</b>
            </h4>

        </template>

        <vs-row v-if="theme != null">
            <!-- BASE FIELDS -->
            <vs-row style="gap: 30px;">
                <vs-input type="text" label="Titolo" v-model="theme.title" block />
            </vs-row>

        </vs-row>

        <template #footer>
            <div class="footer-dialog">
                <vs-button block @click="saveTheme">
                    Salva modifiche
                </vs-button>
            </div>
        </template>
    </vs-dialog>

    <!-- BEGIN PRODUCT MANAGEMENT -->
    <template v-if="active == 'products'">

        <vs-table striped style="width: 100%;" v-model="product">
            <template #thead>
                <vs-tr>
                    <vs-th style="width: 5%;">
                        Id
                    </vs-th>
                    <vs-th style="width: 30%;">
                        Titolo
                    </vs-th>
                    <vs-th>
                        Larghezza
                    </vs-th>
                    <vs-th>
                        Altezza
                    </vs-th>
                    <vs-th>
                        Pagine bianche
                    </vs-th>
                    <vs-th>
                        Layouts
                    </vs-th>
                    <vs-th>
                        Temi
                    </vs-th>
                    <vs-th>
                        Ordini
                    </vs-th>
                </vs-tr>
            </template>
            <template #tbody>
                <vs-tr :key="i" v-for="(tr, i) in products" @click="product = tr" :is-selected="tr == product">
                    <vs-td>
                        {{ tr.id }}
                    </vs-td>
                    <vs-td>
                        {{ tr.title }}
                    </vs-td>

                    <vs-td>
                        {{ tr.width }}mm
                    </vs-td>
                    <vs-td>
                        {{ tr.height }}mm
                    </vs-td>
                    <vs-td>
                        {{ tr.emptyBackCover ? 'Sì' : 'No' }}
                    </vs-td>
                    <vs-td>
                        {{ tr.layouts.length }}
                    </vs-td>
                    <vs-td>
                        {{ tr.themes.length }}
                    </vs-td>
                    <vs-td>
                        {{ tr.orders.length }}
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
    </template>
    <!-- END PRODUCT MANAGEMENT -->

    <!-- BEGIN USER MANAGEMENT-->
    <template v-if="active == 'users'">
        <vs-dialog prevent-close v-model="editUserDialog" class="dialog-large" :loading="editUserDialogLoading">
            <template #header>
                <h4 style="margin-bottom: 0;">
                    Modifica <b>{{ user != null ? user.email : '' }}</b>
                </h4>

            </template>

            <vs-row v-if="user != null">
                <!-- BASE FIELDS -->
                <vs-row style="gap: 30px;">
                    <vs-input type="text" label="Email" disabled v-model="user.email" block />
                    <vs-input type="text" label="Nome" v-model="user.name" block />
                    <vs-input type="text" label="Cognome" v-model="user.surname" block />
                    <vs-input type="text" label="Indirizzo" v-model="user.address" block />
                    <vs-input type="text" label="Indirizzo No." v-model="user.addressNo" block />
                    <vs-input type="text" label="Città" v-model="user.city" block />
                    <vs-input type="text" label="CAP" v-model="user.postalCode" block />
                    <vs-input type="text" label="Paese" v-model="user.country" block />
                    <vs-input type="text" label="Cellulare" v-model="user.mobile" block />
                    <vs-input type="text" label="VAT" v-model="user.vat" block />
                    <vs-input type="text" label="Codice fiscale" v-model="user.cf" block />
                    <vs-input type="text" label="Stripe CID" v-model="user.stripeCustomerId" block />
                    <vs-checkbox v-model="user.isAdmin">
                        Amministratore
                    </vs-checkbox>
                </vs-row>

                <!-- THEME MANAGEMENT -->
                <vs-row justify="space-between" align="center">
                    <h2>Ordini</h2>

                </vs-row>
                <vs-table striped style="width: 100%;">
                    <template #thead>
                        <vs-tr>
                            <vs-th style="width: 5%;">
                                Id
                            </vs-th>

                        </vs-tr>
                    </template>
                    <template #tbody>
                        <vs-tr :key="i" v-for="(tr, i) in user.orders">
                            <vs-td>
                                {{ tr.id }}
                            </vs-td>

                        </vs-tr>
                    </template>
                </vs-table>

            </vs-row>

            <template #footer>
                <div class="footer-dialog">
                    <vs-button block @click="saveUser">
                        Salva modifiche
                    </vs-button>
                </div>
            </template>
        </vs-dialog>
        <vs-table striped style="width: 100%;" v-model="user">
            <template #thead>
                <vs-tr>
                    <vs-th style="width: 5%;">
                        Id
                    </vs-th>
                    <vs-th style="width: 30%;">
                        Email
                    </vs-th>
                    <vs-th>
                        Nome
                    </vs-th>
                    <vs-th>
                        Cognome
                    </vs-th>
                    <vs-th>
                        Indirizzo
                    </vs-th>
                    <vs-th>
                        Città
                    </vs-th>
                    <vs-th>
                        CAP
                    </vs-th>
                    <vs-th>
                        Amministratore
                    </vs-th>
                    <vs-th>
                        Ordini
                    </vs-th>
                    <vs-th>
                        Post
                    </vs-th>
                </vs-tr>
            </template>
            <template #tbody>
                <vs-tr :key="i" v-for="(tr, i) in users" @click="user = tr" :is-selected="tr == user">
                    <vs-td>
                        {{ tr.id }}
                    </vs-td>
                    <vs-td>
                        {{ tr.email }}
                    </vs-td>
                    <vs-td>
                        {{ tr.name }}
                    </vs-td>
                    <vs-td>
                        {{ tr.surname }}
                    </vs-td>
                    <vs-td>
                        {{ tr.address }} {{ tr.addressNo }}
                    </vs-td>
                    <vs-td>
                        {{ tr.city }}
                    </vs-td>
                    <vs-td>
                        {{ tr.postalCode }}
                    </vs-td>
                    <vs-td>
                        {{ tr.isAdmin ? 'Sì' : 'No' }}
                    </vs-td>
                    <vs-td>
                        {{ tr.orders.length }}
                    </vs-td>

                    <vs-td>
                        {{ tr.posts.length }}
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
    </template>

    <!-- BEGIN USER MANAGEMENT-->
    <template v-if="active == 'orders'">
        <vs-input type="text" v-model="orderSearch" label="Cerca" />
        <vs-table striped style="width: 100%;" v-model="user">
            <template #thead>
                <vs-tr>
                    <vs-th style="width: 5%;">
                        Id
                    </vs-th>
                    <vs-th style="width: 30%;">
                        Prodotto
                    </vs-th>
                    <vs-th>
                        Pagine
                    </vs-th>
                    <vs-th>
                        Versione
                    </vs-th>
                    <vs-th>
                        Qty
                    </vs-th>
                    <vs-th>
                        Stato Pagamento
                    </vs-th>
                    <vs-th>
                        Data
                    </vs-th>
                    <vs-th>
                        Stato Elaborazione
                    </vs-th>
                    <vs-th>
                        Importo
                    </vs-th>

                    <vs-th>
                        Origine
                    </vs-th>
                    <vs-th>

                    </vs-th>
                </vs-tr>
            </template>
            <template #tbody>
                <vs-tr :key="i" v-for="(tr, i) in orders">
                    <vs-td>
                        {{ tr.id }}
                    </vs-td>
                    <vs-td>
                        {{ tr.product.title }}
                    </vs-td>
                    <vs-td>
                        {{ tr.pages}}
                    </vs-td>
                    <vs-td>
                        {{ tr.digitalVersionOnly ? 'Digitale' : 'Completa' }}
                    </vs-td>
                    <vs-td>
                        {{ tr.qty }}
                    </vs-td>
                    <vs-td>
                        {{ tr.invoices[0].status }}
                    </vs-td>
                    <vs-td>
                        {{ (new Date(tr.invoices[0].createdAt).toLocaleDateString()) }} {{ (new Date(tr.invoices[0].createdAt).toLocaleTimeString()) }}
                    </vs-td>
                    <vs-th>
                        <template v-if="tr.isFailed">
                            Fallito
                        </template>
                        <template v-else-if="tr.isReady">
                            Completato
                        </template>
                        <template v-else-if="tr.inProgress">
                            In lavorazione
                        </template>
                        <template v-else>
                            In attesa
                        </template>
                    </vs-th>
                    <vs-td>
                        € {{ tr.invoices[0].amount.toFixed(2) }}
                    </vs-td>
                    <vs-td>
                        {{ tr.origin || '-' }}
                    </vs-td>
                    <vs-td style="width: 40%;">
                        <vs-row direction="row" justify="flex-end">
                            <vs-button v-if="tr.invoices[0].stripeInvoiceId == '' || tr.invoices[0].stripeInvoiceId == null && tr.invoices[0].status != 'PAID'" @click="invoice = tr.invoices[0]" dark>Convalida</vs-button>
                            <vs-button :disabled="!tr.isReady" @click="resendEmail(tr)" :loading="resendingEmail">Re-Invia Email</vs-button>
                            <vs-button @click="viewCustomer(tr)">Vis. Cliente</vs-button>
                            <vs-button @click="viewInvoice(tr)">Vis. Ricevuta</vs-button>
                            <vs-button @click="downloadOrder(tr)">Download</vs-button>
                        </vs-row>
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
    </template>

    <!-- BEGIN FONTS MANAGEMENT-->
    <template v-if="active == 'fonts'">

        <vs-row justify="flex-end">
            <vs-button @click="newFont">Carica</vs-button>
        </vs-row>
        <vs-row>
            <vs-table striped style="width: 100%;" v-model="font">
                <template #thead>
                    <vs-tr>
                        <vs-th style="width: 5%;">
                            Id
                        </vs-th>
                        <vs-th style="width: 30%;">
                            Nome
                        </vs-th>
                    </vs-tr>
                </template>
                <template #tbody>
                    <vs-tr :key="i" v-for="(tr, i) in fonts" @click="font = tr" :is-selected="tr == font">
                        <vs-td>
                            {{ tr.id }}
                        </vs-td>
                        <vs-td>
                            {{ tr.name }}
                        </vs-td>
                    </vs-tr>
                </template>
            </vs-table>
        </vs-row>
    </template>
</vs-row>
</template>

<script>
import {
    apiCall,
    baseUrl
} from '../client';

export default ({
    components: {},
    setup() {

    },

    data() {
        return {
            active: '',

            products: [],
            product: null,
            editProductDialog: false,
            editProductDialogLoading: false,

            layout: null,
            editLayoutDialog: false,
            editLayoutDialogLoading: false,

            element: null,
            editElementDialog: false,
            editElementDialogLoading: false,

            theme: null,
            editThemeDialog: false,
            editThemeDialogLoading: false,

            uploadFileForThemeId: null,
            uploadFileForField: null,
            uploadFileLoading: false,

            users: [],
            user: null,

            editUserDialog: false,
            editUserDialogLoading: false,

            fonts: [],
            font: null,
            editFontDialog: false,
            editFontDialogLoading: false,

            elementDefaultFont: null,
            elementDefaultFontDialog: false,
            elementDefaultFontDialogLoading: false,

            productVariant: null,
            productVariantDialog: false,
            productVariantDialogLoading: false,

            orders: [],
            fullOrders: [],
            orderSearch: '',
            resendingEmail: false,

            invoice: null,
            invoiceDialog: false,
            invoiceDialogLoading: false,

            users_count: 0,
        }
    },

    methods: {

        async getUsersCount(){
            var result = await apiCall('GET', '/User/Count');
            if (result.status == 200) {
                this.users_count = result.data.count;
            }
        },
        

        async saveInvoice() {
            this.invoiceDialogLoading = true;
            var oldStatus = this.invoice.status;
            this.invoice.status = 'PAID';
            var result = await apiCall('PATCH', '/Order/Administrator/Invoice', this.invoice);
            this.invoiceDialogLoading = false;
            if (result.status == 200) {
                this.invoiceDialog = false;
                this.$vs.notification({
                    icon: '<i class="bx bx-check"></i>',
                    color: 'success',
                    position: 'top-right',
                    title: 'Fatto!',
                    text: `Pagamento convalidato con successo.`
                })
                this.getOrders();
            } else {
                this.$vs.notification({
                    icon: '<i class="bx bx-error"></i>',
                    color: 'danger',
                    position: 'top-right',
                    title: 'Ops!',
                    text: `Impossibile completare l'operazione.`
                })
                this.invoice.status = oldStatus;
            }
        },
        async resendEmail(order) {
            this.resendingEmail = true;
            var result = await apiCall('POST', '/Order/Administrator/Resend/' + order.id);
            this.resendingEmail = false;
            if (result.status == 200) {
                this.$vs.notification({
                    icon: '<i class="bx bx-check"></i>',
                    color: 'success',
                    position: 'top-right',
                    title: 'Fatto!',
                    text: `Email schedulata con successo.`
                })
                this.getFonts();
            } else {
                this.$vs.notification({
                    icon: '<i class="bx bx-error"></i>',
                    color: 'danger',
                    position: 'top-right',
                    title: 'Ops!',
                    text: `Impossibile completare l'operazione.`
                })
            }
        },

        downloadOrder(o) {
            window.open(baseUrl + '/Order/Administrator/' + o.id + '/Download', "_blank");
        },

        viewInvoice(order) {
            // open stripe invoice in new tab, https://dashboard.stripe.com/test/invoices/in_1OPSwBIg6B130mWneVFdf84Y
            window.open(`https://dashboard.stripe.com/invoices/${order.invoices[0].stripeInvoiceId}`, '_blank');
        },

        viewCustomer(order) {
            // open stripe customer in new tab https://dashboard.stripe.com/test/customers/cus_PDuccrSI06sTmw
            window.open(`https://dashboard.stripe.com/customers/${order.user.stripeCustomerId}`, '_blank');
        },

        newElementDefaultFont(elementId, themeId) {
            this.elementDefaultFont = {
                elementId,
                themeId,
                color: '#000000',
                size: 14,
                fontId: ''
            }
            this.elementDefaultFontDialog = true;
        },

        addProductVariant() {
            this.productVariant = {
                productId: this.product.id,
                pages: 0,
                price: 0,
                digitalPrice: 0,
                stripePriceId: '',
                digitalStripePriceId: ''
            }
        },

        newFont() {
            this.font = {
                name: '',
                fileContent: ''
            }
        },

        uploadLayoutFile(themeId, field) {
            this.uploadFileForThemeId = themeId;
            this.uploadFileForField = field;
            if (field == 'fileId') {
                this.$refs.pdfInput.click();
            } else if (field == 'previewFileId' || field == 'productFileId' || field == 'elementDefault') {
                this.$refs.imageInput.click();
            }
        },

        async fileInputChangeTTF() {
            var vm = this;

            const reader = new FileReader();
            var file = this.$refs.ttfInput.files[0];

            reader.addEventListener("load", async () => {
                vm.font.fileContent = reader.result.split('base64,')[1];
            }, false);
            if (file) {
                reader.readAsDataURL(file);
            }
        },

        async fileInputChange() {
            var vm = this;

            const reader = new FileReader();
            var file;
            if (vm.uploadFileForField == 'fileId') {
                file = this.$refs.pdfInput.files[0];
            } else if (vm.uploadFileForField == 'previewFileId' || vm.uploadFileForField == 'productFileId' || vm.uploadFileForField == 'elementDefault') {
                file = this.$refs.imageInput.files[0];
            }

            reader.addEventListener("load", async () => {
                vm.uploadFileLoading = true;
                var result = await apiCall('PUT', '/Layout/File', {
                    FileContent: reader.result.split('base64,')[1]
                });
                vm.uploadFileLoading = false;
                if (result.status == 200) {
                    if (vm.uploadFileForField == 'productFileId') {
                        vm.product.fileId = result.data.id;
                        this.saveProduct();
                    } else if (vm.uploadFileForField == 'elementDefault') {
                        var r = await this.addElementDefault({
                            elementId: this.element.id,
                            themeId: this.uploadFileForThemeId,
                            fileId: result.data.id
                        });
                        if (r != false) {
                            this.element.elementDefaults = this.element.elementDefaults.filter(e => e.themeId != vm.uploadFileForThemeId); // rimuovo riga che tanto è stata sostituita
                            this.element.elementDefaults.push(r); // aggiungo nuova
                        }
                    } else {
                        var e = vm.layout.layoutFiles.find(e => e.themeId == vm.uploadFileForThemeId);
                        if (e == null) {
                            e = {
                                layoutId: vm.layout.id,
                                themeId: vm.uploadFileForThemeId,
                                fileId: null,
                                previewFileId: null
                            };
                            e[vm.uploadFileForField] = result.data.id;
                            vm.layout.layoutFiles.push(e);
                        } else {
                            e[vm.uploadFileForField] = result.data.id;
                        }

                        this.saveLayoutFile(e);
                    }

                } else {
                    vm.$vs.notification({
                        icon: '<i class="bx bx-error"></i>',
                        color: 'danger',
                        position: 'top-right',
                        title: 'Ops!',
                        text: `Impossibile caricare il file (HTTP/${result.status}).`
                    })
                }
            }, false);
            if (file) {
                reader.readAsDataURL(file);
            }
        },

        async getProducts() {
            var result = await apiCall('POST', '/Product'); // post instead of get because of conflict in user endpoint
            if (result.status == 200) {
                this.products = result.data;
            }
        },

        async getOrders() {
            var result = await apiCall('GET', '/Order/Administrator'); // post instead of get because of conflict in user endpoint
            if (result.status == 200) {
                this.orders = result.data;
                this.fullOrders = result.data;
            }
        },

        async getFonts() {
            var result = await apiCall('GET', '/Font');
            if (result.status == 200) {
                this.fonts = result.data;
            }
        },

        async getUsers() {
            var result = await apiCall('GET', '/User/All');
            if (result.status == 200) {
                this.users = result.data;
            }
        },

        async addElementDefault(d) {
            var result = await apiCall('PUT', '/Product/ElementDefault', d);
            if (result.status == 200) {
                return result.data;
            }
            return false;
        },

        async saveFont() {
            this.editFontDialogLoading = true;
            var result = await apiCall('PUT', '/Font', this.font);
            this.editFontDialogLoading = false;
            if (result.status == 200) {
                this.$vs.notification({
                    icon: '<i class="bx bx-check"></i>',
                    color: 'success',
                    position: 'top-right',
                    title: 'Fatto!',
                    text: `Tutte le modifiche sono state salvate.`
                })
                this.getFonts();
            } else {
                this.$vs.notification({
                    icon: '<i class="bx bx-error"></i>',
                    color: 'danger',
                    position: 'top-right',
                    title: 'Ops!',
                    text: `Impossibile salvare le modifiche.`
                })
            }
        },

        async saveProduct() {
            this.editProductDialogLoading = true;
            var result = await apiCall('PATCH', '/Product', {
                id: this.product.id,
                title: this.product.title,
                description: this.product.description,
                width: parseFloat(this.product.width.toString().replace(',', '.')),
                height: parseFloat(this.product.height.toString().replace(',', '.')),
                emptyBackCover: this.product.emptyBackCover,
                fileId: this.product.fileId
            });
            this.editProductDialogLoading = false;
            if (result.status == 200) {
                this.$vs.notification({
                    icon: '<i class="bx bx-check"></i>',
                    color: 'success',
                    position: 'top-right',
                    title: 'Fatto!',
                    text: `Tutte le modifiche sono state salvate.`
                })
            } else {
                this.$vs.notification({
                    icon: '<i class="bx bx-error"></i>',
                    color: 'danger',
                    position: 'top-right',
                    title: 'Ops!',
                    text: `Impossibile salvare le modifiche.`
                })
            }
        },

        async saveProductVariant() {
            this.productVariantDialogLoading = true;
            var result = await apiCall('PUT', '/Product/ProductVariant', {
                id: this.productVariant.id,
                productId: this.productVariant.productId,
                pages: parseInt(this.productVariant.pages),
                stripePriceId: this.productVariant.stripePriceId,
                digitalStripePriceId: this.productVariant.digitalStripePriceId,
                price: parseFloat(this.productVariant.price.toString().replace(',', '.')),
                digitalPrice: parseFloat(this.productVariant.digitalPrice.toString().replace(',', '.'))
            });
            this.productVariantDialogLoading = false;
            if (result.status == 200) {
                this.$vs.notification({
                    icon: '<i class="bx bx-check"></i>',
                    color: 'success',
                    position: 'top-right',
                    title: 'Fatto!',
                    text: `Tutte le modifiche sono state salvate.`
                })
            } else {
                this.$vs.notification({
                    icon: '<i class="bx bx-error"></i>',
                    color: 'danger',
                    position: 'top-right',
                    title: 'Ops!',
                    text: `Impossibile salvare le modifiche.`
                })
            }
        },

        async saveUser() {
            this.editUserDialogLoading = true;
            var result = await apiCall('PATCH', '/User/All', {
                id: this.user.id,
                email: this.user.email,
                name: this.user.name,
                surname: this.user.surname,
                address: this.user.address,
                addressNo: this.user.addressNo,
                city: this.user.city,
                postalCode: this.user.postalCode,
                isAdmin: this.user.isAdmin,
                password: this.user.password,
                proPicFileId: this.user.proPicFileId,
                country: this.user.country,
                mobile: this.user.mobile,
                vat: this.user.vat,
                cf: this.user.cf,
                stripeCustomerId: this.user.stripeCustomerId,

            });
            this.editUserDialogLoading = false;
            if (result.status == 200) {
                this.$vs.notification({
                    icon: '<i class="bx bx-check"></i>',
                    color: 'success',
                    position: 'top-right',
                    title: 'Fatto!',
                    text: `Tutte le modifiche sono state salvate.`
                })
            } else {
                this.$vs.notification({
                    icon: '<i class="bx bx-error"></i>',
                    color: 'danger',
                    position: 'top-right',
                    title: 'Ops!',
                    text: `Impossibile salvare le modifiche.`
                })
            }
        },

        addTheme() {
            this.theme = {
                id: 0,
                title: '',
                productId: this.product.id
            }
        },

        async saveTheme() {
            this.editThemeDialogLoading = true;
            var result = await apiCall('PATCH', '/Product/Theme', {
                id: this.theme.id,
                title: this.theme.title,
                productId: this.theme.productId
            });
            this.editThemeDialogLoading = false;
            if (result.status == 200) {
                this.$vs.notification({
                    icon: '<i class="bx bx-check"></i>',
                    color: 'success',
                    position: 'top-right',
                    title: 'Fatto!',
                    text: `Tutte le modifiche sono state salvate.`
                })
                if (this.theme.id == 0) { // nuovo elemento
                    this.product.themes.push(result.data);
                }
                this.editThemeDialog = false;
            } else {
                this.$vs.notification({
                    icon: '<i class="bx bx-error"></i>',
                    color: 'danger',
                    position: 'top-right',
                    title: 'Ops!',
                    text: `Impossibile salvare le modifiche.`
                })
            }
        },

        async saveElementDefaultFont() {
            this.elementDefaultFontDialogLoading = true;
            var result = await apiCall('PUT', '/Product/ElementDefaultFont', {
                elementId: this.elementDefaultFont.elementId,
                themeId: this.elementDefaultFont.themeId,
                fontId: parseInt(this.elementDefaultFont.fontId),
                size: parseInt(this.elementDefaultFont.size),
                color: this.elementDefaultFont.color
            });
            this.elementDefaultFontDialogLoading = false;
            if (result.status == 200) {
                this.$vs.notification({
                    icon: '<i class="bx bx-check"></i>',
                    color: 'success',
                    position: 'top-right',
                    title: 'Fatto!',
                    text: `Tutte le modifiche sono state salvate.`
                })
                this.element.elementDefaultFonts = this.element.elementDefaultFonts.filter(e => e.themeId != this.elementDefaultFont.themeId);
                this.element.elementDefaultFonts.push(result.data);
                this.editThemeDialog = false;
            } else {
                this.$vs.notification({
                    icon: '<i class="bx bx-error"></i>',
                    color: 'danger',
                    position: 'top-right',
                    title: 'Ops!',
                    text: `Impossibile salvare le modifiche.`
                })
            }
        },

        async addLayout() {
            this.editProductDialogLoading = true;
            var result = await apiCall('PATCH', '/Product/Layout', {
                id: 0,
                productId: this.product.id,
                isFrontCover: false,
                isBackCover: false
            });
            this.editProductDialogLoading = false;
            if (result.status == 200) {
                this.$vs.notification({
                    icon: '<i class="bx bx-check"></i>',
                    color: 'success',
                    position: 'top-right',
                    title: 'Fatto!',
                    text: `Tutte le modifiche sono state salvate.`
                })
                this.product.layouts.push(result.data);
            } else {
                this.$vs.notification({
                    icon: '<i class="bx bx-error"></i>',
                    color: 'danger',
                    position: 'top-right',
                    title: 'Ops!',
                    text: `Impossibile salvare le modifiche.`
                })
            }
        },

        async editLayout() {
            this.editLayoutDialogLoading = true;
            var result = await apiCall('PATCH', '/Product/Layout', {
                id: this.layout.id,
                productId: this.layout.productId,
                isFrontCover: this.layout.isFrontCover,
                isBackCover: this.layout.isBackCover,
                isBlankPage: this.layout.isBlankPage,
            });
            this.editLayoutDialogLoading = false;
            if (result.status == 200) {
                this.$vs.notification({
                    icon: '<i class="bx bx-check"></i>',
                    color: 'success',
                    position: 'top-right',
                    title: 'Fatto!',
                    text: `Tutte le modifiche sono state salvate.`
                })
            } else {
                this.$vs.notification({
                    icon: '<i class="bx bx-error"></i>',
                    color: 'danger',
                    position: 'top-right',
                    title: 'Ops!',
                    text: `Impossibile salvare le modifiche.`
                })
            }
        },

        addElement() {
            this.element = {
                id: 0,
                title: '',
                layoutId: this.layout.id,
                type: '',
                posX: 0,
                posY: 0,
                height: 0,
                width: 0,
                elementDefaults: [],
                defaultText: '',
                defaultFontHalign: 'CENTER',
                defaultFontValign: 'CENTER',
                skipPostSelection: false,
                hidden: false
            }
        },

        async saveElement() {
            this.editElementDialogLoading = true;
            var result = await apiCall('PATCH', '/Product/Element', {
                id: this.element.id,
                title: this.element.title,
                layoutId: this.element.layoutId,
                type: this.element.type,
                posX: parseFloat(this.element.posX.toString().replace(',', '.')),
                posY: parseFloat(this.element.posY.toString().replace(',', '.')),
                height: parseFloat(this.element.height.toString().replace(',', '.')),
                width: parseFloat(this.element.width.toString().replace(',', '.')),
                defaultText: this.element.defaultText,
                skipPostSelection: this.element.skipPostSelection,
                hidden: this.element.hidden,
                defaultFontHalign: this.element.defaultFontHalign,
                defaultFontValign: this.element.defaultFontValign,
            });
            this.editElementDialogLoading = false;
            if (result.status == 200) {
                this.$vs.notification({
                    icon: '<i class="bx bx-check"></i>',
                    color: 'success',
                    position: 'top-right',
                    title: 'Fatto!',
                    text: `Tutte le modifiche sono state salvate.`
                })
                if (this.element.id == 0) { // nuovo elemento
                    this.layout.elements.push(result.data);
                }
                this.editThemeDialog = false;
            } else {
                this.$vs.notification({
                    icon: '<i class="bx bx-error"></i>',
                    color: 'danger',
                    position: 'top-right',
                    title: 'Ops!',
                    text: `Impossibile salvare le modifiche.`
                })
            }
        },

        async saveLayoutFile(layoutFile) {
            this.editLayoutDialogLoading = true;
            var result = await apiCall('PATCH', '/Product/LayoutFile', layoutFile);
            this.editLayoutDialogLoading = false;
            if (result.status == 200) {
                this.$vs.notification({
                    icon: '<i class="bx bx-check"></i>',
                    color: 'success',
                    position: 'top-right',
                    title: 'Fatto!',
                    text: `Tutte le modifiche sono state salvate.`
                })

            } else {
                this.$vs.notification({
                    icon: '<i class="bx bx-error"></i>',
                    color: 'danger',
                    position: 'top-right',
                    title: 'Ops!',
                    text: `Impossibile salvare le modifiche.`
                })
            }
        },

        openProductFile(id, pdf = false) {
            window.open(baseUrl + '/Layout/File/' + id + (pdf ? '/PDF' : ''), '_blank');
        }
    },

    watch: {
        orderSearch(n) {
            if (n != '') {
                this.orders = this.fullOrders.filter(o => (
                    o.id.toString().includes(n) ||
                    o.product.title.toLowerCase().includes(n.toLowerCase()) ||
                    o.invoices[0].status.toLowerCase().includes(n.toLowerCase())
                ));
            } else {
                this.orders = this.fullOrders;
            }
        },

        invoice(n) {
            if (n != null) {
                this.invoiceDialog = true;
            }
        },

        invoiceDialog(n) {
            if (!n) {
                this.invoice = null;
            }
        },

        active(n) {
            console.log(n);
            if (n == 'products') {
                this.getProducts();
            } else if (n == 'users') {
                this.getUsers();
            } else if (n == 'fonts') {
                this.getFonts();
            } else if (n == 'orders') {
                this.getOrders();
            }
        },

        '$parent.user'(n) {
            if (n != null) {
                this.active = 'products';
            }
        },

        font(n) {
            if (n != null) {
                this.editFontDialog = true;
            }
        },

        user(n) {
            if (n != null) {
                this.editUserDialog = true;
            }
        },

        editUserDialog(n) {
            if (!n) {
                this.user = null;
            }
        },

        product(n) {
            if (n != null) {
                this.editProductDialog = true;
            }
        },

        layout(n) {
            if (n != null) {
                this.editLayoutDialog = true;
            }
        },

        element(n) {
            if (n != null) {
                this.editElementDialog = true;
            }
        },

        theme(n) {
            if (n != null) {
                this.editThemeDialog = true;
            }
        },

        editProductDialog(n) {
            if (!n) {
                this.product = null;
            }
        },

        editLayoutDialog(n) {
            if (!n) {
                this.layout = null;
            }
        },

        editElementDialog(n) {
            if (!n) {
                this.element = null;
            }
        },

        editThemeDialog(n) {
            if (!n) {
                this.theme = null;
            }
        },

        productVariant(n) {
            if (n != null) {
                this.productVariantDialog = true;
            }
        },

        productVariantDialog(n) {
            if (!n) {
                this.productVariant = null;
            }
        }
    },

    async mounted() {
        if (this.$parent.user != null) {
            this.active = 'products';
        }
        this.getFonts();
        this.getUsersCount();

    }
})
</script>

<style>
.dialog-large .vs-dialog {
    width: 50%;
}

.vs-select__label--label {
    margin-left: 20px;
}

.vs-table-content {
    overflow-y: scroll;
    height: 100%;
}

.dialog-disabled .vs-dialog__content, .dialog-disabled .vs-dialog__footer, .dialog-disabled label{
    pointer-events: none;
    cursor: not-allowed!important;
}
</style>
